import { Spacer } from '~/components/Common/Spacer'

interface ButtonProps {
    /**
     * Text or label of button
     */
    text?: string
    /**
     * What type of button
     */
    type?: keyof typeof buttonTypeMap
    /**
     * How big the button
     */
    size?: keyof typeof buttonSizeMap
    /**
     * Set button width manually
     */
    width?: number | string
    /**
     * Overwrite text color
     */
    color?: string
    /**
     * Overwrite background color
     */
    backgroundColor?: string
    /**
     * Is button disabled
     */
    disabled?: boolean
    /**
     * Loading state
     */
    loading?: boolean
    /**
     * hide button text on loading state
     */
    hideButtonTextOnLoading?: boolean
    /**
     * Icon for button
     */
    icon?: JSX.Element
    /**
     * Icon position
     */
    iconPosition?: 'left' | 'right'
    /**
     * Full width button state
     */
    fullWidth?: boolean
    /**
     * Hover color classes
     */
    hoverColor?: string
    /**
     * Ring color classes
     */
    ringColor?: string
    /**
     * Id of element
     */
    id?: string
    /**
     * Optional space between text and icon
     */
    spaceBetweenIconText?: boolean
    /**
     * Optional click event
     */
    onClick?: () => void
    /**
     * Option click event when disabled
     */
    onDisabledClick?: () => void
}

interface LoaderProps {
    variant: keyof typeof buttonTypeMap
    removeMargin?: boolean
}

const Loader = ({ variant, removeMargin }: LoaderProps) => {
    return (
        <span>
            <svg
                aria-hidden="true"
                role="status"
                className={`inline w-4 h-4 animate-spin ${!removeMargin && `mr-4`}`}
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill={['primary', 'tertiary', 'error'].includes(variant) ? '#D4D4D4' : '#FFFFFF'}
                />
                <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                />
            </svg>
        </span>
    )
}

export const Button = ({
    text,
    type = 'primary',
    size = 'md',
    width,
    color,
    backgroundColor,
    disabled = false,
    loading = false,
    hideButtonTextOnLoading = false,
    icon,
    iconPosition = 'left',
    fullWidth = false,
    hoverColor,
    ringColor,
    id,
    spaceBetweenIconText,
    onClick,
    onDisabledClick
}: ButtonProps) => {
    const handleClick = () => {
        if (loading) {
            return
        }

        if (disabled) {
            if (onDisabledClick) onDisabledClick()
            return
        }

        if (!onClick) {
            return
        }

        onClick()
    }

    const buttonLabel = (
        <>
            {!!icon && iconPosition === 'left' && (
                <>
                    {icon}
                    {spaceBetweenIconText && <Spacer></Spacer>}
                    {!!text && <div className={buttonSizeMap[size].iconMargin}></div>}
                </>
            )}
            <span className="inline-block font-bold">{text}</span>
            {!!icon && iconPosition === 'right' && (
                <>
                    {!!text && <div className={buttonSizeMap[size].iconMargin}></div>}
                    {spaceBetweenIconText && <Spacer></Spacer>}
                    {icon}
                </>
            )}
        </>
    )

    return (
        <>
            <button
                id={id}
                type="button"
                className={`inline-flex items-center justify-center relative text-center overflow-hidden flex-shrink-0 flex-grow-0 leading-normal rounded-lg outline outline-1 ${
                    disabled
                        ? 'bg-neutrals-100 text-neutrals-400 focus:ring-0 outline-none after:bg-neutrals-300 cursor-not-allowed'
                        : `${buttonTypeMap[type]} ${buttonSizeMap[size].ring} ${!loading && buttonEffectClasses} ${
                              !!hoverColor ? `after:bg-${hoverColor}` : buttonTypeHoverMap[type]
                          } ${!!ringColor ? `ring-${ringColor}` : buttonTypeRingMap[type]}`
                } ${buttonSizeMap[size].wrap}`}
                style={{
                    width: fullWidth ? '100%' : width,
                    color,
                    backgroundColor,
                    minWidth: !!icon && !text ? 0 : undefined
                }}
                onClick={() => handleClick()}
                role="button"
                aria-label="button"
                data-testid="button_component">
                <div className="inline-flex items-center justify-center w-full h-full z-1">
                    <>
                        {loading && !hideButtonTextOnLoading && (
                            <>
                                {loading && <Loader variant={type} />}
                                {buttonLabel}
                            </>
                        )}
                        {loading && hideButtonTextOnLoading && <Loader variant={type} removeMargin />}
                        {!loading && buttonLabel}
                    </>
                </div>
            </button>
        </>
    )
}

const buttonTypeMap = {
    primary: 'bg-primary-600 text-neutrals-light outline-transparent',
    secondary: 'bg-secondary-600 text-neutrals-light outline-transparent',
    tertiary: 'bg-tertiary-600 text-neutrals-light outline-transparent',
    success: 'bg-green-600 text-neutrals-light outline-transparent',
    error: 'bg-red-600 text-neutrals-light outline-transparent',
    warning: 'bg-amber-600 text-neutrals-light outline-transparent',
    information: 'bg-blue-600 text-neutrals-light outline-transparent',
    outlined: 'bg-neutrals-light text-neutrals-900 outline-neutrals-100 focus:outline-neutrals-light'
}

const buttonTypeHoverMap = {
    primary: 'after:bg-primary-800',
    secondary: 'after:bg-secondary-800',
    tertiary: 'after:bg-tertiary-800',
    success: 'after:bg-green-800',
    error: 'after:bg-red-800',
    warning: 'after:bg-amber-800',
    information: 'after:bg-blue-800',
    outlined: 'after:bg-neutrals-50'
}

const buttonTypeRingMap = {
    primary: 'ring-primary-400',
    secondary: 'ring-secondary-400',
    tertiary: 'ring-tertiary-400',
    success: 'ring-green-400',
    error: 'ring-red-400',
    warning: 'ring-amber-300',
    information: 'ring-blue-400',
    outlined: 'ring-primary-200'
}

const buttonSizeMap = {
    xs: {
        wrap: 'px-3 py-2 text-xs min-w-button-xs',
        ring: 'focus:ring-2',
        iconMargin: 'w-2'
    },
    sm: {
        wrap: 'px-3 py-2 text-sm min-w-button-sm',
        ring: 'focus:ring-2',
        iconMargin: 'w-2'
    },
    md: {
        wrap: 'px-4.5 py-2.5 text-sm min-w-button-md',
        ring: 'focus:ring-3',
        iconMargin: 'w-2'
    },
    lg: {
        wrap: 'px-4.5 py-3 text-base min-w-button-lg',
        ring: 'focus:ring-3',
        iconMargin: 'w-3'
    },
    xl: {
        wrap: 'px-6 py-3.5 text-base min-w-button-xl',
        ring: 'focus:ring-3',
        iconMargin: 'w-3'
    }
}

const buttonEffectClasses =
    "transition-transform duration-200 active:translate-y-1 after:content-[''] after:absolute after:top-1/2 after:left-1/2 after:w-2/12 after:h-full after:bg-opacity-50 after:-translate-x-1/2 after:-translate-y-1/2 after:opacity-0 after:transition-width-radius after:duration-300 hover:after:opacity-100 hover:after:origin-center hover:after:w-full hover:after:rounded-lg"
