import { CurrencyCode, CurrencyCodeConfig } from '../types/currency'

export const currencyConfig: { [key in CurrencyCode]: CurrencyCodeConfig } = {
    [CurrencyCode.IDR]: {
        name: 'Indonesian Rupiah',
        symbol: 'Rp',
        decimals: 0,
        decimalSeparator: ',',
        thousandsSeparator: '.',
        roundType: 'round',
        round: true,
        noSpaceBetweenSymbolAndValue: true,
        minChartUpperBound: 900,
        emoji: '🇮🇩'
    },
    [CurrencyCode.MYR]: {
        name: 'Malaysian Ringgit',
        symbol: 'RM',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        noSpaceBetweenSymbolAndValue: true,
        minChartUpperBound: 90,
        emoji: '🇲🇾'
    },
    [CurrencyCode.THB]: {
        name: 'Thai Baht',
        symbol: '฿',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇹🇭'
    },
    [CurrencyCode.SGD]: {
        name: 'Singapore Dollar',
        symbol: '$',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇸🇬'
    },
    [CurrencyCode.PHP]: {
        name: 'Philippine Peso',
        symbol: '₱',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇵🇭'
    },
    [CurrencyCode.VND]: {
        name: 'Vietnamese Dong',
        symbol: '₫',
        decimals: 0,
        decimalSeparator: ',',
        thousandsSeparator: '.',
        roundType: 'round',
        round: true,
        minChartUpperBound: 900,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇻🇳'
    },
    [CurrencyCode.KHR]: {
        name: 'Cambodian Riel',
        symbol: '៛',
        decimals: 0,
        decimalSeparator: ',',
        thousandsSeparator: '.',
        roundType: 'round',
        round: true,
        minChartUpperBound: 900,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇰🇭'
    },
    [CurrencyCode.BND]: {
        name: 'Brunei Dollar',
        symbol: '$',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇧🇳'
    },
    [CurrencyCode.LAK]: {
        name: 'Lao Kip',
        symbol: '₭',
        decimals: 0,
        decimalSeparator: ',',
        thousandsSeparator: '.',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇱🇦'
    },
    [CurrencyCode.MMK]: {
        name: 'Myanmar Kyat',
        symbol: 'Ks',
        decimals: 0,
        decimalSeparator: ',',
        thousandsSeparator: '.',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇲🇲'
    },
    [CurrencyCode.USD]: {
        name: 'US Dollar',
        symbol: '$',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇺🇸'
    },
    [CurrencyCode.GBP]: {
        name: 'British Pound',
        symbol: '£',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇬🇧'
    },
    [CurrencyCode.EUR]: {
        name: 'Euro',
        symbol: '€',
        decimals: 2,
        decimalSeparator: ',',
        thousandsSeparator: '.',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇪🇺'
    },
    [CurrencyCode.AUD]: {
        name: 'Australian Dollar',
        symbol: 'A$',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇦🇺'
    },
    [CurrencyCode.CAD]: {
        name: 'Canadian Dollar',
        symbol: 'C$',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇨🇦'
    },
    [CurrencyCode.NZD]: {
        name: 'New Zealand Dollar',
        symbol: 'NZ$',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇳🇿'
    },
    [CurrencyCode.CNY]: {
        name: 'Chinese Yuan',
        symbol: '¥',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇨🇳'
    },
    [CurrencyCode.INR]: {
        name: 'Indian Rupee',
        symbol: '₹',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇮🇳'
    },
    [CurrencyCode.JPY]: {
        name: 'Japanese Yen',
        symbol: '¥',
        decimals: 0,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'round',
        round: true,
        minChartUpperBound: 900,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇯🇵'
    },
    [CurrencyCode.KRW]: {
        name: 'South Korean Won',
        symbol: '₩',
        decimals: 0,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'round',
        round: true,
        minChartUpperBound: 900,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇰🇷'
    },
    [CurrencyCode.HKD]: {
        name: 'Hong Kong Dollar',
        symbol: 'HK$',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇭🇰'
    },
    [CurrencyCode.CHF]: {
        name: 'Swiss Franc',
        symbol: 'CHF',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇨🇭'
    },
    [CurrencyCode.SEK]: {
        name: 'Swedish Krona',
        symbol: 'kr',
        decimals: 2,
        decimalSeparator: ',',
        thousandsSeparator: '.',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇸🇪'
    },
    [CurrencyCode.NOK]: {
        name: 'Norwegian Krone',
        symbol: 'kr',
        decimals: 2,
        decimalSeparator: ',',
        thousandsSeparator: '.',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇳🇴'
    },
    [CurrencyCode.DKK]: {
        name: 'Danish Krone',
        symbol: 'kr',
        decimals: 2,
        decimalSeparator: ',',
        thousandsSeparator: '.',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇩🇰'
    },
    [CurrencyCode.RUB]: {
        name: 'Russian Ruble',
        symbol: '₽',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇷🇺'
    },
    [CurrencyCode.ZAR]: {
        name: 'South African Rand',
        symbol: 'R',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇿🇦'
    },
    [CurrencyCode.BRL]: {
        name: 'Brazilian Real',
        symbol: 'R$',
        decimals: 2,
        decimalSeparator: ',',
        thousandsSeparator: '.',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇧🇷'
    },
    [CurrencyCode.MXN]: {
        name: 'Mexican Peso',
        symbol: '$',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇲🇽'
    },
    [CurrencyCode.PLN]: {
        name: 'Polish Zloty',
        symbol: 'zł',
        decimals: 2,
        decimalSeparator: ',',
        thousandsSeparator: '.',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇵🇱'
    },
    [CurrencyCode.TRY]: {
        name: 'Turkish Lira',
        symbol: '₺',
        decimals: 2,
        decimalSeparator: ',',
        thousandsSeparator: '.',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇹🇷'
    },
    [CurrencyCode.SAR]: {
        name: 'Saudi Riyal',
        symbol: '﷼',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇸🇦'
    },
    [CurrencyCode.AED]: {
        name: 'United Arab Emirates Dirham',
        symbol: 'د.إ',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇦🇪'
    },
    [CurrencyCode.ARS]: {
        name: 'Argentine Peso',
        symbol: '$',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇦🇷'
    },
    [CurrencyCode.COP]: {
        name: 'Colombian Peso',
        symbol: '$',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇨🇴'
    },
    [CurrencyCode.CLP]: {
        name: 'Chilean Peso',
        symbol: '$',
        decimals: 0,
        decimalSeparator: ',',
        thousandsSeparator: '.',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇨🇱'
    },
    [CurrencyCode.PEN]: {
        name: 'Peruvian Sol',
        symbol: 'S/.',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇵🇪'
    },
    [CurrencyCode.EGP]: {
        name: 'Egyptian Pound',
        symbol: 'E£',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇪🇬'
    },
    [CurrencyCode.NGN]: {
        name: 'Nigerian Naira',
        symbol: '₦',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇳🇬'
    },
    [CurrencyCode.KES]: {
        name: 'Kenyan Shilling',
        symbol: 'KSh',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇰🇪'
    },
    [CurrencyCode.GHS]: {
        name: 'Ghanaian Cedi',
        symbol: '₵',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇬🇭'
    },
    [CurrencyCode.TZS]: {
        name: 'Tanzanian Shilling',
        symbol: 'TSh',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇹🇿'
    },
    [CurrencyCode.UGX]: {
        name: 'Ugandan Shilling',
        symbol: 'USh',
        decimals: 0,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 900,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇺🇬'
    },
    [CurrencyCode.PKR]: {
        name: 'Pakistani Rupee',
        symbol: '₨',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇵🇰'
    },
    [CurrencyCode.BDT]: {
        name: 'Bangladeshi Taka',
        symbol: '৳',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇧🇩'
    },
    [CurrencyCode.LKR]: {
        name: 'Sri Lankan Rupee',
        symbol: 'Rs',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇱🇰'
    },
    [CurrencyCode.NPR]: {
        name: 'Nepalese Rupee',
        symbol: '₨',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇳🇵'
    },
    [CurrencyCode.NAD]: {
        name: 'Namibian Dollar',
        symbol: 'N$',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇳🇦'
    },
    [CurrencyCode.BHD]: {
        name: 'Bahraini Dinar',
        symbol: '.د.ب',
        decimals: 3,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇧🇭'
    },
    [CurrencyCode.QAR]: {
        name: 'Qatari Riyal',
        symbol: 'ر.ق',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇶🇦'
    },
    [CurrencyCode.OMR]: {
        name: 'Omani Rial',
        symbol: 'ر.ع.',
        decimals: 3,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇴🇲'
    },
    [CurrencyCode.KWD]: {
        name: 'Kuwaiti Dinar',
        symbol: 'د.ك',
        decimals: 3,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇰🇼'
    },
    [CurrencyCode.JOD]: {
        name: 'Jordanian Dinar',
        symbol: 'د.ا',
        decimals: 3,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇯🇴'
    },
    [CurrencyCode.MAD]: {
        name: 'Moroccan Dirham',
        symbol: 'د.م.',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇲🇦'
    },
    [CurrencyCode.DZD]: {
        name: 'Algerian Dinar',
        symbol: 'د.ج',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇩🇿'
    },
    [CurrencyCode.TND]: {
        name: 'Tunisian Dinar',
        symbol: 'د.ت',
        decimals: 3,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇹🇳'
    },
    [CurrencyCode.ILS]: {
        name: 'Israeli New Shekel',
        symbol: '₪',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇮🇱'
    },
    [CurrencyCode.CZK]: {
        name: 'Czech Koruna',
        symbol: 'Kč',
        decimals: 2,
        decimalSeparator: ',',
        thousandsSeparator: '.',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇨🇿'
    },
    [CurrencyCode.HUF]: {
        name: 'Hungarian Forint',
        symbol: 'Ft',
        decimals: 0,
        decimalSeparator: ',',
        thousandsSeparator: '.',
        roundType: 'ceil',
        round: true,
        minChartUpperBound: 900,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇭🇺'
    },
    [CurrencyCode.RON]: {
        name: 'Romanian Leu',
        symbol: 'lei',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇷🇴'
    },
    [CurrencyCode.HRK]: {
        name: 'Croatian Kuna',
        symbol: 'kn',
        decimals: 2,
        decimalSeparator: ',',
        thousandsSeparator: '.',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇭🇷'
    },
    [CurrencyCode.BGN]: {
        name: 'Bulgarian Lev',
        symbol: 'лв',
        decimals: 2,
        decimalSeparator: ',',
        thousandsSeparator: '.',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇧🇬'
    },
    [CurrencyCode.ISK]: {
        name: 'Icelandic Krona',
        symbol: 'kr',
        decimals: 0,
        decimalSeparator: ',',
        thousandsSeparator: '.',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 900,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇮🇸'
    },
    [CurrencyCode.UAH]: {
        name: 'Ukrainian Hryvnia',
        symbol: '₴',
        decimals: 2,
        decimalSeparator: ',',
        thousandsSeparator: '.',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇺🇦'
    },
    [CurrencyCode.GEL]: {
        name: 'Georgian Lari',
        symbol: '₾',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇬🇪'
    },
    [CurrencyCode.KZT]: {
        name: 'Kazakhstani Tenge',
        symbol: '₸',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇰🇿'
    },
    [CurrencyCode.AZN]: {
        name: 'Azerbaijani Manat',
        symbol: '₼',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇦🇿'
    },
    [CurrencyCode.BYN]: {
        name: 'Belarusian Ruble',
        symbol: 'Br',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇧🇾'
    },
    [CurrencyCode.AMD]: {
        name: 'Armenian Dram',
        symbol: '֏',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇦🇲'
    },
    [CurrencyCode.MKD]: {
        name: 'Macedonian Denar',
        symbol: 'ден',
        decimals: 2,
        decimalSeparator: ',',
        thousandsSeparator: '.',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇲🇰'
    },
    [CurrencyCode.RSD]: {
        name: 'Serbian Dinar',
        symbol: 'дин',
        decimals: 2,
        decimalSeparator: ',',
        thousandsSeparator: '.',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇷🇸'
    },
    [CurrencyCode.ALL]: {
        name: 'Albanian Lek',
        symbol: 'L',
        decimals: 0,
        decimalSeparator: ',',
        thousandsSeparator: '.',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 900,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇦🇱'
    },
    [CurrencyCode.MDL]: {
        name: 'Moldovan Leu',
        symbol: 'L',
        decimals: 2,
        decimalSeparator: ',',
        thousandsSeparator: '.',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇲🇩'
    },
    [CurrencyCode.BAM]: {
        name: 'Bosnia and Herzegovina Convertible Mark',
        symbol: 'KM',
        decimals: 2,
        decimalSeparator: ',',
        thousandsSeparator: '.',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇧🇦'
    },
    [CurrencyCode.MNT]: {
        name: 'Mongolian Tugrik',
        symbol: '₮',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇲🇳'
    },
    [CurrencyCode.KGS]: {
        name: 'Kyrgyzstani Som',
        symbol: 'som',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇰🇬'
    },
    [CurrencyCode.TJS]: {
        name: 'Tajikistani Somoni',
        symbol: 'SM',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇹🇯'
    },
    [CurrencyCode.TMT]: {
        name: 'Turkmenistani Manat',
        symbol: 'm',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇹🇲'
    },
    [CurrencyCode.UZS]: {
        name: 'Uzbekistani Som',
        symbol: 'сўм',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇺🇿'
    },
    [CurrencyCode.VEF]: {
        name: 'Venezuelan Bolívar',
        symbol: 'Bs',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇻🇪'
    },
    [CurrencyCode.BOB]: {
        name: 'Bolivian Boliviano',
        symbol: 'Bs',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇧🇴'
    },
    [CurrencyCode.PYG]: {
        name: 'Paraguayan Guaraní',
        symbol: '₲',
        decimals: 0,
        decimalSeparator: ',',
        thousandsSeparator: '.',
        roundType: 'ceil',
        round: true,
        minChartUpperBound: 900,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇵🇾'
    },
    [CurrencyCode.UYU]: {
        name: 'Uruguayan Peso',
        symbol: '$U',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇺🇾'
    },
    [CurrencyCode.GYD]: {
        name: 'Guyanese Dollar',
        symbol: '$',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇬🇾'
    },
    [CurrencyCode.SRD]: {
        name: 'Surinamese Dollar',
        symbol: '$',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇸🇷'
    },
    [CurrencyCode.FJD]: {
        name: 'Fijian Dollar',
        symbol: '$',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇫🇯'
    },
    [CurrencyCode.PGK]: {
        name: 'Papua New Guinean Kina',
        symbol: 'K',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇵🇬'
    },
    [CurrencyCode.SBD]: {
        name: 'Solomon Islands Dollar',
        symbol: '$',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇸🇧'
    },
    [CurrencyCode.VUV]: {
        name: 'Vanuatu Vatu',
        symbol: 'VT',
        decimals: 0,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 900,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇻🇺'
    },
    [CurrencyCode.IRR]: {
        name: 'Iranian Rial',
        symbol: '﷼',
        decimals: 0,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 900,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇮🇷'
    },
    [CurrencyCode.SYP]: {
        name: 'Syrian Pound',
        symbol: 'LS',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇸🇾'
    },
    [CurrencyCode.DOP]: {
        name: 'Dominican Peso',
        symbol: 'RD$',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇩🇴'
    },
    [CurrencyCode.JMD]: {
        name: 'Jamaican Dollar',
        symbol: 'J$',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇯🇲'
    },
    [CurrencyCode.BSD]: {
        name: 'Bahamian Dollar',
        symbol: '$',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇧🇸'
    },
    [CurrencyCode.TTD]: {
        name: 'Trinidad and Tobago Dollar',
        symbol: 'TT$',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇹🇹'
    },
    [CurrencyCode.XAF]: {
        name: 'Central African CFA Franc',
        symbol: 'FCFA',
        decimals: 0,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'round',
        round: true,
        minChartUpperBound: 900,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇨🇫'
    },
    [CurrencyCode.XOF]: {
        name: 'West African CFA Franc',
        symbol: 'CFA',
        decimals: 0,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'round',
        round: true,
        minChartUpperBound: 900,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🌍'
    },
    [CurrencyCode.XPF]: {
        name: 'CFP Franc',
        symbol: 'CFPF',
        decimals: 0,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'round',
        round: true,
        minChartUpperBound: 900,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇵🇫'
    },
    [CurrencyCode.CRC]: {
        name: 'Costa Rican Colón',
        symbol: '₡',
        decimals: 0,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'round',
        round: true,
        minChartUpperBound: 900,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇨🇷'
    },
    [CurrencyCode.HNL]: {
        name: 'Honduran Lempira',
        symbol: 'L',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇭🇳'
    },
    [CurrencyCode.NIO]: {
        name: 'Nicaraguan Córdoba',
        symbol: 'C$',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇳🇮'
    },
    [CurrencyCode.PAB]: {
        name: 'Panamanian Balboa',
        symbol: 'B/.',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇵🇦'
    },
    [CurrencyCode.CUP]: {
        name: 'Cuban Peso',
        symbol: '$MN',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇨🇺'
    },
    [CurrencyCode.LBP]: {
        name: 'Lebanese Pound',
        symbol: 'LBP',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇱🇧'
    },
    [CurrencyCode.SLL]: {
        name: 'Sierra Leonean Leone',
        symbol: 'Le',
        decimals: 0,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'round',
        round: true,
        minChartUpperBound: 900,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇸🇰'
    },
    [CurrencyCode.SDG]: {
        name: 'Sudanese Pound',
        symbol: 'SDG',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇸🇩'
    },
    [CurrencyCode.MWK]: {
        name: 'Malawian Kwacha',
        symbol: 'MK',
        decimals: 0,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'round',
        round: true,
        minChartUpperBound: 900,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇲🇼'
    },
    [CurrencyCode.RWF]: {
        name: 'Rwandan Franc',
        symbol: 'RF',
        decimals: 0,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'round',
        round: true,
        minChartUpperBound: 900,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇷🇼'
    },
    [CurrencyCode.BIF]: {
        name: 'Burundian Franc',
        symbol: 'FBu',
        decimals: 0,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'round',
        round: true,
        minChartUpperBound: 900,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇧🇮'
    },
    [CurrencyCode.XCD]: {
        name: 'East Caribbean Dollar',
        symbol: '$',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇩🇲'
    },
    [CurrencyCode.BWP]: {
        name: 'Botswana Pula',
        symbol: 'P',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇧🇼'
    },
    [CurrencyCode.ZWL]: {
        name: 'Zimbabwean Dollar',
        symbol: '$',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇿🇼'
    },
    [CurrencyCode.SCR]: {
        name: 'Seychellois Rupee',
        symbol: 'SR',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇸🇨'
    },
    [CurrencyCode.GTQ]: {
        name: 'Guatemalan Quetzal',
        symbol: 'Q',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇬🇹'
    },
    [CurrencyCode.BTN]: {
        name: 'Bhutanese Ngultrum',
        symbol: 'Nu',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇧🇹'
    },
    [CurrencyCode.MOP]: {
        name: 'Macanese Pataca',
        symbol: 'MOP$',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇲🇴'
    },
    [CurrencyCode.SHP]: {
        name: 'Saint Helena Pound',
        symbol: 'SHP',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇸🇭'
    },
    [CurrencyCode.FKP]: {
        name: 'Falkland Islands Pound',
        symbol: 'FK£',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇫🇰'
    },
    [CurrencyCode.IQD]: {
        name: 'Iraqi Dinar',
        symbol: 'ع.د',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇮🇶'
    },
    [CurrencyCode.YER]: {
        name: 'Yemeni Rial',
        symbol: '﷼',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇾🇪'
    },
    [CurrencyCode.MUR]: {
        name: 'Mauritian Rupee',
        symbol: '₨',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇲🇺'
    },
    [CurrencyCode.MRU]: {
        name: 'Mauritanian Ouguiya',
        symbol: 'UM',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇲🇷'
    },
    [CurrencyCode.ZMW]: {
        name: 'Zambian Kwacha',
        symbol: 'ZK',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇿🇲'
    },
    [CurrencyCode.SOS]: {
        name: 'Somali Shilling',
        symbol: 'S',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇸🇴'
    },
    [CurrencyCode.DJF]: {
        name: 'Djiboutian Franc',
        symbol: 'Fdj',
        decimals: 0,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'round',
        round: true,
        minChartUpperBound: 900,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇩🇯'
    },
    [CurrencyCode.KPW]: {
        name: 'North Korean Won',
        symbol: '₩',
        decimals: 0,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'round',
        round: true,
        minChartUpperBound: 900,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇰🇵'
    },
    [CurrencyCode.MVR]: {
        name: 'Maldivian Rufiyaa',
        symbol: 'Rf',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇲🇻'
    },
    [CurrencyCode.CUC]: {
        name: 'Cuban Convertible Peso',
        symbol: 'CUC$',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇨🇺'
    },
    [CurrencyCode.GIP]: {
        name: 'Gibraltar Pound',
        symbol: '£',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇬🇮'
    },
    [CurrencyCode.MZN]: {
        name: 'Mozambican Metical',
        symbol: 'MT',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇲🇿'
    },
    [CurrencyCode.STN]: {
        name: 'São Tomé and Príncipe Dobra',
        symbol: 'Db',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇸🇹'
    },
    [CurrencyCode.SSP]: {
        name: 'South Sudanese Pound',
        symbol: 'SS£',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇸🇸'
    },
    [CurrencyCode.BZD]: {
        name: 'Belize Dollar',
        symbol: 'BZ$',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇧🇿'
    },
    [CurrencyCode.KYD]: {
        name: 'Cayman Islands Dollar',
        symbol: 'CI$',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇰🇾'
    },
    [CurrencyCode.WST]: {
        name: 'Samoan Tala',
        symbol: 'T',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇼🇸'
    },
    [CurrencyCode.TOP]: {
        name: 'Tongan Paʻanga',
        symbol: 'T$',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇹🇴'
    },
    [CurrencyCode.KMF]: {
        name: 'Comorian Franc',
        symbol: 'CF',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'round',
        round: true,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇰🇲'
    },
    [CurrencyCode.AOA]: {
        name: 'Angolan Kwanza',
        symbol: 'Kz',
        decimals: 2,
        decimalSeparator: ',',
        thousandsSeparator: '.',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇦🇴'
    },
    [CurrencyCode.AWG]: {
        name: 'Aruban Florin',
        symbol: 'ƒ',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇦🇼'
    },
    [CurrencyCode.CVE]: {
        name: 'Cape Verdean Escudo',
        symbol: 'Esc',
        decimals: 2,
        decimalSeparator: ',',
        thousandsSeparator: '.',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇨🇻'
    },
    [CurrencyCode.HTG]: {
        name: 'Haitian Gourde',
        symbol: 'HTG',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇭🇹'
    },
    [CurrencyCode.ANG]: {
        name: 'Netherlands Antillean Guilder',
        symbol: 'NAƒ',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇳🇱'
    },
    [CurrencyCode.TWD]: {
        name: 'New Taiwan Dollar',
        symbol: 'NT$',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true,
        emoji: '🇹🇼'
    }
}

export const getCurrencyList = () => {
    return Object.values(CurrencyCode)
        .map((code) => ({
            code,
            ...currencyConfig[code]
        }))
        .sort((a, b) => a.name.localeCompare(b.name))
}
