export enum CurrencyCode {
    // A – Countries beginning with A (or its adjective)
    ALL = 'ALL', // Albanian Lek
    DZD = 'DZD', // Algerian Dinar
    AOA = 'AOA', // Angolan Kwanza
    ARS = 'ARS', // Argentine Peso
    AMD = 'AMD', // Armenian Dram
    AWG = 'AWG', // Aruban Florin
    AUD = 'AUD', // Australian Dollar
    AZN = 'AZN', // Azerbaijani Manat

    // B – Countries beginning with B
    BHD = 'BHD', // Bahraini Dinar
    BDT = 'BDT', // Bangladeshi Taka
    BSD = 'BSD', // Bahamian Dollar
    BYN = 'BYN', // Belarusian Ruble
    BZD = 'BZD', // Belize Dollar
    BTN = 'BTN', // Bhutanese Ngultrum
    BOB = 'BOB', // Bolivian Boliviano
    BAM = 'BAM', // Bosnia and Herzegovina Convertible Mark
    BWP = 'BWP', // Botswana Pula
    BRL = 'BRL', // Brazilian Real
    BND = 'BND', // Brunei Dollar
    GBP = 'GBP', // British Pound
    BGN = 'BGN', // Bulgarian Lev
    BIF = 'BIF', // Burundian Franc

    // C – "C"-countries and close
    KHR = 'KHR', // Cambodian Riel
    CAD = 'CAD', // Canadian Dollar
    CVE = 'CVE', // Cape Verdean Escudo
    KYD = 'KYD', // Cayman Islands Dollar
    CZK = 'CZK', // Czech Koruna
    CLP = 'CLP', // Chilean Peso
    CNY = 'CNY', // Chinese Yuan
    COP = 'COP', // Colombian Peso
    KMF = 'KMF', // Comorian Franc
    CRC = 'CRC', // Costa Rican Colón
    CUC = 'CUC', // Cuban Convertible Peso
    CUP = 'CUP', // Cuban Peso
    HRK = 'HRK', // Croatian Kuna

    // D – Denmark & Dominican
    DKK = 'DKK', // Danish Krone
    DJF = 'DJF', // Djiboutian Franc
    DOP = 'DOP', // Dominican Peso

    // E – Egypt and Euro (as a pan–European currency)
    EGP = 'EGP', // Egyptian Pound
    EUR = 'EUR', // Euro

    // F – F
    FKP = 'FKP', // Falkland Islands Pound
    FJD = 'FJD', // Fijian Dollar

    // G – "G"-countries
    GEL = 'GEL', // Georgian Lari
    GHS = 'GHS', // Ghanaian Cedi
    GIP = 'GIP', // Gibraltar Pound
    GTQ = 'GTQ', // Guatemalan Quetzal
    GYD = 'GYD', // Guyanese Dollar

    // H – Haiti, Honduras, Hong Kong, Hungary
    HTG = 'HTG', // Haitian Gourde
    HNL = 'HNL', // Honduran Lempira
    HKD = 'HKD', // Hong Kong Dollar
    HUF = 'HUF', // Hungarian Forint

    // I – Island, India, Indonesia, Iran, Iraq, Israel
    ISK = 'ISK', // Icelandic Króna
    INR = 'INR', // Indian Rupee
    IDR = 'IDR', // Indonesian Rupiah
    IRR = 'IRR', // Iranian Rial
    IQD = 'IQD', // Iraqi Dinar
    ILS = 'ILS', // Israeli New Shekel

    // J – Jamaica, Japan, Jordan
    JMD = 'JMD', // Jamaican Dollar
    JPY = 'JPY', // Japanese Yen
    JOD = 'JOD', // Jordanian Dinar

    // K – Kazakhstan, Kenya, Kyrgyzstan, Korea(s), Kuwait
    KZT = 'KZT', // Kazakhstani Tenge
    KES = 'KES', // Kenyan Shilling
    KGS = 'KGS', // Kyrgyzstani Som
    KPW = 'KPW', // North Korean Won
    KRW = 'KRW', // Korean Won
    KWD = 'KWD', // Kuwaiti Dinar

    // L – Lao, Lebanon, (Sri Lankan)
    LAK = 'LAK', // Lao Kip
    LBP = 'LBP', // Lebanese Pound
    LKR = 'LKR', // Sri Lankan Rupee

    // M – (Moldova, Macedonia, Myanmar, Mongolia, Macao, Mauritania, Mauritius, Maldives, Malaysia, Mexico, Morocco, Mozambique)
    MDL = 'MDL', // Moldovan Leu
    MKD = 'MKD', // Macedonian Denar
    MMK = 'MMK', // Myanmar Kyat
    MNT = 'MNT', // Mongolian Tugrik
    MOP = 'MOP', // Macanese Pataca
    MRU = 'MRU', // Mauritanian Ouguiya
    MUR = 'MUR', // Mauritian Rupee
    MWK = 'MWK', // Malawian Kwacha
    MVR = 'MVR', // Maldivian Rufiyaa
    MYR = 'MYR', // Malaysian Ringgit
    MXN = 'MXN', // Mexican Peso
    MAD = 'MAD', // Moroccan Dirham
    MZN = 'MZN', // Mozambican Metical

    // N – Namibia, Nepal, Netherlands Antillean, Nigeria, Norway, New Zealand, Nicaragua
    NAD = 'NAD', // Namibian Dollar
    NPR = 'NPR', // Nepalese Rupee
    ANG = 'ANG', // Netherlands Antillean Guilder
    NGN = 'NGN', // Nigerian Naira
    NOK = 'NOK', // Norwegian Krone
    NZD = 'NZD', // New Zealand Dollar
    NIO = 'NIO', // Nicaraguan Córdoba

    // O
    OMR = 'OMR', // Omani Rial

    // P – Pakistan, Panama, Papua New Guinea, Paraguay, Peru, Philippines, Poland
    PKR = 'PKR', // Pakistani Rupee
    PAB = 'PAB', // Panamanian Balboa
    PGK = 'PGK', // Papua New Guinean Kina
    PYG = 'PYG', // Paraguayan Guaraní
    PEN = 'PEN', // Peruvian Sol
    PHP = 'PHP', // Philippine Peso
    PLN = 'PLN', // Polish Zloty

    // Q
    QAR = 'QAR', // Qatari Riyal

    // R – Romania, Russia, Rwanda, Serbia
    RON = 'RON', // Romanian Leu
    RUB = 'RUB', // Russian Ruble
    RWF = 'RWF', // Rwandan Franc
    RSD = 'RSD', // Serbian Dinar

    // S – A large group (sorted approximately by the country adjective)
    SHP = 'SHP', // Saint Helena Pound
    SAR = 'SAR', // Saudi Riyal
    WST = 'WST', // Samoan Tala
    SGD = 'SGD', // Singapore Dollar
    SCR = 'SCR', // Seychellois Rupee
    SLL = 'SLL', // Sierra Leonean Leone
    SBD = 'SBD', // Solomon Islands Dollar
    SOS = 'SOS', // Somali Shilling
    ZAR = 'ZAR', // South African Rand
    SSP = 'SSP', // South Sudanese Pound
    SDG = 'SDG', // Sudanese Pound
    SYP = 'SYP', // Syrian Pound
    SEK = 'SEK', // Swedish Krona
    CHF = 'CHF', // Swiss Franc
    SRD = 'SRD', // Surinamese Dollar
    STN = 'STN', // São Tomé and Príncipe Dobra

    // T – Taiwan, Tajikistan, Tanzania, Thailand, Tunisia, Tonga, Turkey, Trinidad, Turkmenistan
    TWD = 'TWD', // New Taiwan Dollar
    TJS = 'TJS', // Tajikistani Somoni
    TZS = 'TZS', // Tanzanian Shilling
    THB = 'THB', // Thai Baht
    TND = 'TND', // Tunisian Dinar
    TOP = 'TOP', // Tongan Paʻanga
    TRY = 'TRY', // Turkish Lira
    TTD = 'TTD', // Trinidad and Tobago Dollar
    TMT = 'TMT', // Turkmenistani Manat

    // U – Uganda and other U's
    UGX = 'UGX', // Ugandan Shilling
    UAH = 'UAH', // Ukrainian Hryvnia
    USD = 'USD', // United States Dollar
    UYU = 'UYU', // Uruguayan Peso
    UZS = 'UZS', // Uzbekistani Som
    AED = 'AED', // United Arab Emirates Dirham

    // V
    VUV = 'VUV', // Vanuatu Vatu
    VEF = 'VEF', // Venezuelan Bolívar Fuerte
    VND = 'VND', // Vietnamese Dong

    // Y
    YER = 'YER', // Yemeni Rial

    // Z
    ZMW = 'ZMW', // Zambian Kwacha
    ZWL = 'ZWL', // Zimbabwean Dollar

    // X – Additional "X" codes not yet placed in alphabetical order by country
    XAF = 'XAF', // Central African CFA Franc
    XPF = 'XPF', // CFP Franc
    XCD = 'XCD', // East Caribbean Dollar
    XOF = 'XOF' // West African CFA Franc
}

export interface CurrencyCodeConfig {
    name: string
    symbol: string
    decimals: number
    decimalSeparator: string
    thousandsSeparator: string
    roundType: 'round' | 'ceil' | 'floor'
    round: boolean
    noSpaceBetweenSymbolAndValue?: boolean
    minChartUpperBound?: number
    emoji?: string
}
