import Image from 'next/image'
import { useRouter } from 'next/router'
import { Fragment, useEffect, useMemo, useState } from 'react'

import { LucidePlusCircle } from 'lucide-react'
import { useAuth } from '~/components/Auth/AuthProvider'
import { logout } from '~/components/Auth/Logout'
import { Spacer } from '~/components/Common/Spacer'
import { DropdownWrapper } from '~/components/Dropdown/DropdownWrapper'
import Icon from '~/components/Icon'
import { useLanguage } from '~/components/Language/LanguageProvider'
import { Sidebar } from '~/components/Sidebar/Sidebar'
import { SidebarListItem } from '~/components/Sidebar/SidebarListItem'
import { SidebarListSubitem } from '~/components/Sidebar/SidebarListSubitem'
import { SubscriptionLimitExceeded } from '~/components/Subscription/SubscriptionLimitExceeded'
import { useSubscription } from '~/components/Subscription/SubscriptionProvider'
import { Button } from '~/components/ui/button'
import { Select, SelectContent, SelectItem, SelectSeparator, SelectTrigger, SelectValue } from '~/components/ui/select'
import { responsiveBreakpointMap } from '~/constants'
import { COLORS } from '~/constants/colors'
import { navigationMenu } from '~/constants/navigationMenu'
import $posthog from '~/lib/posthog'
import $sentry from '~/lib/sentry'
import { delayedCallback, getCurrentBusiness, setCurrentBusiness } from '~/utils'
import { DesktopPrompt } from '../DesktopPrompt'

interface DashboardProps {
    /**
     * Extend element
     */
    children: JSX.Element | JSX.Element[]
    /**
     * Curent route
     */
    pathname?: string
    /**
     * Hide navigation
     */
    hideNavigation?: boolean
    /**
     * Show Mobile version
     */
    showMobile?: boolean
}

export const Dashboard = ({ children, pathname = '', hideNavigation, showMobile = false }: DashboardProps) => {
    const { profile, businesses, currentBusiness, checkAccess } = useAuth()
    const { t } = useLanguage()
    const { execFeature, getSubscription } = useSubscription()
    const [active, setActive] = useState(false)
    const router = useRouter()
    const parentRoute = '/' + pathname.split('/')[2]
    const [showMobileSidebar] = useState(false)
    const [profileDropdownState, setProfileDropdownState] = useState(false)

    const [isSubscribed] = getSubscription()

    const handClickItem = (slug: string) => {
        if (slug === '') {
            return
        }

        router.push(`/business${slug}`)
    }

    const haveAccessToManageStaff = () => {
        return currentBusiness()?.role === 'owner'
    }

    useEffect(() => {
        const business = currentBusiness()
        if (business)
            $posthog.group('business', business.businessId, {
                name: business.name,
                category: business.category,
                subscription_package: business.subscription?.productId.split('_')[1] || 'free',
                subscription_start_date: business.subscription?.startDate,
                subscription_expired_date: business.subscription?.endDate
            })
    }, [currentBusiness()])

    const sidebarItems = useMemo(() => {
        return navigationMenu.map((item) => {
            if (
                item.slug === '/report' &&
                !checkAccess('readAccountingReport') &&
                !checkAccess('readStockReport') &&
                !checkAccess('readCustomerReport')
            ) {
                return <Fragment key={item.slug}></Fragment>
            }

            return (
                <SidebarListItem
                    active={item.slug === parentRoute}
                    key={item.slug}
                    icon={item.icon}
                    shrink={active}
                    onClick={() => {
                        if (item.slug === '/contact') {
                            return
                        }
                        handClickItem(item.slug)
                    }}
                    subitem={
                        item.submenu &&
                        item.submenu.map((subitem) =>
                            subitem.slug === '/report' && !checkAccess('readAccountingReport') ? (
                                <Fragment key={subitem.slug}></Fragment>
                            ) : subitem.slug === '/stock-report' && !checkAccess('readStockReport') ? (
                                <Fragment key={subitem.slug}></Fragment>
                            ) : subitem.slug === '/customer-report' && !checkAccess('readCustomerReport') ? (
                                <Fragment key={subitem.slug}></Fragment>
                            ) : subitem.slug === '/staff' && !haveAccessToManageStaff() ? (
                                <Fragment key={subitem.slug}></Fragment>
                            ) : (
                                <SidebarListSubitem
                                    active={subitem.slug === parentRoute}
                                    key={subitem.slug}
                                    onClick={() => handClickItem(subitem.slug)}>
                                    {t(subitem.text)}
                                </SidebarListSubitem>
                            )
                        )
                    }>
                    {t(item.text)}
                </SidebarListItem>
            )
        })
    }, [navigationMenu, parentRoute, active, checkAccess, haveAccessToManageStaff])

    if (window.innerWidth < responsiveBreakpointMap.md && !showMobile) {
        return (
            <>
                <DesktopPrompt></DesktopPrompt>
            </>
        )
    }

    return (
        <main className="w-full h-full bg-neutrals-100 flex flex-col">
            <div className="flex-grow flex overflow-hidden relative">
                {!hideNavigation && (
                    <Sidebar
                        shrink={active}
                        showMobileSidebar={showMobileSidebar}
                        prepend={
                            <div
                                className={`${
                                    active ? 'px-2 mb-2' : 'px-4'
                                } mt-4 border-b border-neutrals-100 h-[54px]`}>
                                <Select
                                    value={getCurrentBusiness()}
                                    onOpenChange={() => setActive(false)}
                                    onValueChange={(value) => {
                                        setCurrentBusiness(value)

                                        delayedCallback(() => {
                                            window.location.href = `/business/product`
                                        }, 250)
                                    }}>
                                    <SelectTrigger className="bg-white font-semibold w-full">
                                        <SelectValue placeholder="Select a business"></SelectValue>
                                    </SelectTrigger>
                                    <SelectContent>
                                        <div className="p-1 w-full">
                                            <Button
                                                variant="outline"
                                                className="w-full gap-2"
                                                onClick={() => router.push('/business')}>
                                                <LucidePlusCircle size={18}></LucidePlusCircle>
                                                <p>{t('common.add')}</p>
                                            </Button>
                                        </div>
                                        <SelectSeparator></SelectSeparator>
                                        {businesses.map((item) => (
                                            <SelectItem key={item.businessId} value={item.businessId}>
                                                <div className="flex items-center truncate flex-grow flex-shrink">
                                                    {item.imageUrl ? (
                                                        <div className="w-5 h-5 mr-2 rounded-full flex items-center justify-center overflow-hidden bg-neutrals-200 flex-shrink-0">
                                                            <Image
                                                                src={item.imageUrl}
                                                                width={20}
                                                                height={20}
                                                                alt=""></Image>
                                                        </div>
                                                    ) : (
                                                        <div className="flex-shrink-0 w-5 h-5 mr-2 bg-primary-100 rounded-full"></div>
                                                    )}
                                                    <span className="truncate">{item.name}</span>
                                                </div>
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </div>
                        }
                        append={
                            <div className="px-4 pb-4">
                                <DropdownWrapper
                                    dropdownMinWidth={200}
                                    onOpen={() => setProfileDropdownState(true)}
                                    onClose={() => setProfileDropdownState(false)}
                                    onSelect={({ value }) => {
                                        if (value === 'logout') {
                                            logout()
                                            return
                                        }

                                        if (value === 'account') {
                                            router.push(`/business/${value}`)
                                            return
                                        }

                                        if (value === 'feedback') {
                                            window.open('https://feedback.kelola.co/saran-fitur', '_blank')
                                        }

                                        if (value === 'contact') {
                                            window.open('https://go.kelola.co/wa', '_blank')
                                        }
                                    }}
                                    options={[
                                        {
                                            text: t('common.logout'),
                                            value: 'logout',
                                            icon: <Icon name="logout"></Icon>
                                        },
                                        {
                                            text: t('common.setting'),
                                            value: 'account',
                                            icon: <Icon name="cog"></Icon>
                                        },
                                        {
                                            text: t('common.contact_us'),
                                            value: 'contact',
                                            icon: <Icon name="chat-outline"></Icon>
                                        },
                                        {
                                            text: t('common.feedback'),
                                            value: 'feedback',
                                            icon: <Icon name="information-circle"></Icon>
                                        }
                                    ]}>
                                    <div
                                        role="button"
                                        className={`bg-primary-50 flex items-center cursor-pointer px-3 py-3 rounded-full ${
                                            profileDropdownState ? 'bg-primary-100' : 'hover:bg-primary-100'
                                        }`}>
                                        <div className="w-7 h-7 mr-3 rounded-full flex items-center justify-center overflow-hidden bg-neutrals-200 flex-shrink-0">
                                            {profile && profile.imageUrl.length > 0 && (
                                                <Image
                                                    src={profile.imageUrl}
                                                    width={28}
                                                    height={28}
                                                    unoptimized
                                                    alt={''}
                                                    onError={() =>
                                                        $sentry.sendSentryEvent(
                                                            'Error loading image: ' + profile.imageUrl
                                                        )
                                                    }></Image>
                                            )}
                                        </div>
                                        <div className="truncate">
                                            {profile?.name !== '' ? profile?.name : profile?.phoneNumber}
                                        </div>
                                        <Spacer></Spacer>
                                        <Icon name="dots-vertical" color={COLORS.MAIN.NEUTRALS[400]}></Icon>
                                    </div>
                                </DropdownWrapper>
                            </div>
                        }>
                        <>
                            {!isSubscribed && (
                                <div
                                    className={`${
                                        active ? 'px-0' : 'p-2'
                                    } hover:bg-amber-50 flex items-center rounded-lg`}
                                    role="button"
                                    onClick={() => execFeature()}>
                                    <Icon name="premium-outline" color={COLORS.SEMANTIC.AMBER['600']} size="xl"></Icon>
                                    <div className="w-3"></div>
                                    <p className="font-bold bg-gradient-to-r to-amber-400 from-amber-600 bg-clip-text inline-block text-transparent underline">
                                        {t('common.become_vip')}
                                    </p>
                                </div>
                            )}
                            {sidebarItems}
                        </>
                    </Sidebar>
                )}

                <div className="bg-neutrals-50 relative flex flex-col overflow-hidden flex-grow" id="right-hand-side">
                    <SubscriptionLimitExceeded path={pathname}></SubscriptionLimitExceeded>
                    {children}
                </div>
            </div>
        </main>
    )
}
